import React from 'react';
import "../Contactform/style.css";
import { Col, Row, Form, Button, Container } from 'react-bootstrap';
// import emailjs from 'emailjs-com';
import image from "../../assets/v2-assets/v2-Engage-Box-Banner.png"


function Contactform() {
    const FormHead = image;
    return (
        
        <div class="col-md contact-form column">
                <img className="contact-form"src={FormHead} />
                <form
                    className="contact-form"
                    name="contact form"
                    method="POST"
                    data-netlify="true"
                    onSubmit="submit">

                <input type="hidden" name="form-name" value="contact form" />
                <div className="form-group">
                    <label for="name">NAME:</label>
                    <input type="name" className="form-control" placeholder="NAME" name="name" />
                </div>
                <div className="form-group">
                    <label for="businessName">BUSINESS NAME:</label>
                    <input type="name" className="form-control" placeholder="BUSINESS NAME" name="business" />
                </div>
                <div className="form-group">
                    <label for="phoneNumber">PHONE NUMBER:</label>
                    <input type="tel" className="form-control" placeholder="PHONE NUMBER" name="phone" />
                </div>
                <div className="form-group">
                    <label for="email">EMAIL ADDRESS:</label>
                    <input type="email" className="form-control" placeholder="EMAIL ADDRESS" name="email" />
                </div>
                <div className="form-group">
                    <label for="message">MESSAGE:</label>
                    <textarea className="form-control" rows="5" name="message"></textarea>
                </div>
                <br></br>
                <button type="submit" class="btn button-color">SUBMIT</button>
            </form>
            </div>


        
    )
}

export default Contactform;
