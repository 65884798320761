import { Col, Row, Container } from "react-bootstrap";
import "./style.css";
import ethosImage from "../../assets/v2-assets/v2-Our-Ethos-Globe-cropped.png"
import efficiencyImage from "../../assets/v2-assets/v2-Ethos-1Efficiency.png"
import trustImage from "../../assets/v2-assets/v2-Ethos-2Trust.png"
import humanityImage from "../../assets/v2-assets/v2-Ethos-3Humanity.png"
import sustainabilityImage from "../../assets/v2-assets/v2-Ethos-4Sustainability.png"

function Ethos() {
    const ethos = ethosImage;
    const effeciency = efficiencyImage;
    const trust = trustImage;
    const humanity = humanityImage;
    const sustainability = sustainabilityImage;

    return (
        <Container fluid className="container-color justify-content-center">
            <Row>
                <Col className="ethos-title" sm={12}><img src={ethos} alt="Globe containing 'ETHOS' " /></Col>
            </Row>

            <Row className="justify-content-center">
                <Col className="ethos-text wing-images wing-left" md={12} lg={3}>
                    <img src={effeciency} alt="EFFICIENCY: We decreade the complexity of transactions, reduce overall costs, increase transparency and quicken the speed of information spread." />
                </Col>
                <Col className="ethos-text" md={12} lg={3}>
                    <img src={trust} alt="TRUST: We uphold our ongoing commitment to protect privacy, strengthen cypersecurity, maintain our network deployments and enhance end-to-end trust. " />
                </Col>
                <Col className="ethos-text" md={12} lg={3}>
                    <img src={humanity} alt="HUMANITY: We believe your talented, dedicated team members are greatly valued. We partner with you to redeploy, upskill and train talent within you organization." />
                </Col>
                <Col className="ethos-text wing-images wing-right" md={12} lg={3}>
                    <img src={sustainability} alt="SUSTAINABILITY: We avoid proof-of-work consensus algorithms and lead innovation in the most relevant technologies to reduce both our and your carbon footprints." />
                </Col>
            </Row>
            <div className="mobile-spacing-div"></div>
        </Container>
    )
}

export default Ethos;