//Dependecies//
import { Container, Col, Row } from "react-bootstrap"
//Components//
import Ethos from "./components/Ethos"
import Header from "./components/Header"
import Contactform from "./components/Contactform"
import Main from "./components/Main"
import Banner from "./components/Banner";
import Footer from "./components/Footer";
import './App.css';

function App() {
  return (
    <Container  fluid className="App app-container">

      <Header />
      <Col className="bg-image">
        <Container className="margin">
          <Row m-0 className="margin">
            <Col sm={12} lg={true}>
              <Main />
            </Col>
            <Col sm={12} lg={true}  >
              <Contactform />
            </Col>
          </Row>
        </Container>
      </Col>
      <Ethos />
      <Banner />
      <Footer />

    </Container>
  );
}

export default App;
