import { Col, Row, Container } from "react-bootstrap";
import "./style.css";
import { FaFacebookF, FaLinkedinIn, FaTwitter, FaInstagram } from "react-icons/fa"
import verdethoslogo from "../../assets/v2-assets/v2-Left-Heading.png"
function Footer() {
    const logo = verdethoslogo
    const faceBookLink = 'https://www.facebook.com/Verdethos-104028121863090';
    const linkedInLink = 'https://www.linkedin.com/company/73044099/admin/';
    const twitterLink = 'https://twitter.com/verdethos';
    const instagramLink = 'https://www.instagram.com/verdethos/';

    return (
        <Container fluid className="container-color justify-content-center ">
            <Row className=" py-2 color-nav">
                <Container text-center className="footer">
                    <br/>
                    <img
                        src={logo}
                        alt="Veredthos Sustainable Blockchain Logo"
                    />

                    <p className="text-muted mb-0 py-2">CONNECT  WITH US</p>
                    <a href={faceBookLink} target="_blank" rel="noreferrer noopener"><FaFacebookF className="icon-style" /></a>
                    <a href={linkedInLink} target="_blank" rel="noreferrer noopener"><FaLinkedinIn className="icon-style" /></a>
                    <a href={twitterLink} target="_blank" rel="noreferrer noopener"><FaTwitter className="icon-style" /></a>
                    <a href={instagramLink} target="_blank" rel="noreferrer noopener"><FaInstagram className="icon-style" /></a>
                    <br />
                    <p className="text-muted mb-0 py-2">
                        <br/>
                        info@verdethos.io
                        <br />
                        <br />
                        Verdethos
                        <br/>
                        Minneapolis Grain Exchange
                        <br/>
                        400 S. 4th Street 401-240
                        <br/>
                        Minneapolis, MN 55415
                        <br />
                        <br className="not-needed-links"/>
                        <a className="not-needed-links" href="">Privacy|</a> <a className="not-needed-links" href="">Terms|</a> <a className="not-needed-links" href="">Disclaimer</a>
                        <br className="not-needed-links"/>
                        <br/>
                        Copyright © 2021
                        <br />
                        Verdethos LLC, All Rights Reserved
                    </p>
                </Container>
            </Row>
        </Container>
    )
}

export default Footer;