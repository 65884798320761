

import "./style.css"


function Banner() {



    return (

        <div className="banner">
            <div className="banner-text">
                
            </div>
        </div>
    )
}

export default Banner;