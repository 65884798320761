import "../Main/style.css";
import { Container, Row, Col } from "react-bootstrap"
import image from "../../assets/images/Verdethos.jpeg"

function Main() {
    const logo = image;
    return (
        <Container  >
            <Row className="justify-center">
                <Col sm={12} >
                    <img className="logo"
                    src={logo}
                    alt="Verdethos Logo"
                ></img>
                
                </Col>

            </Row >
            <Row className="justify-center">
                <Col sm={12} className="about-text">
                    <p>
                        BUILDING SCALABLE BLOCKCHAIN SOLUTIONS
                        <br></br>
                        ACROSS COMPLEX GLOBAL SUPPLY CHAINS
                    </p>
                </Col>

            </Row>

        </Container>
    )
}

export default Main;