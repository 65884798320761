import "../Header/style.css";
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap"
import VerdethosCropped from "../../assets/v2-assets/v2-Left-Heading.png"

import {FaFacebookF, FaLinkedinIn, FaTwitter, FaInstagram} from "react-icons/fa"

function Header() {

    
    const faceBookLink = 'https://www.facebook.com/Verdethos-104028121863090';
    const linkedInLink = 'https://www.linkedin.com/company/73044099/admin/';
    const twitterLink = 'https://twitter.com/verdethos';
    const instagramLink = 'https://www.instagram.com/verdethos/';

    const logo = VerdethosCropped;
    return (
        <div>
            <Navbar variant="dark" expand="lg" sticky="top" className="color-nav mobile">
                <Navbar.Brand className="mobile banner-image">
                    <img
                        src={logo}
                        width="auto"
                        height="75"
                        
                        className="d-inline-block align-top"
                        alt="Verdethos logo"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" className="button-color hide-socials" />
                <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end hide-socials">
                    <Nav className="mr-auto hide-socials socials-nav">
                       
                        <a href={faceBookLink} target="_blank" rel="noreferrer noopener"><FaFacebookF className="icon-style" /></a>
                        <a href={linkedInLink} target="_blank" rel="noreferrer noopener"><FaLinkedinIn className="icon-style" /></a>
                        <a href={twitterLink} target="_blank" rel="noreferrer noopener"><FaTwitter className="icon-style" /></a>
                        <a href={instagramLink} target="_blank" rel="noreferrer noopener"><FaInstagram className="icon-style" /></a>
                                             
                    </Nav>
                </Navbar.Collapse>
            </Navbar>

        </div>
    )
}

export default Header;